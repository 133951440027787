import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../store/sagaActions';
import { alumniColumnDefs } from '../AlumniTable/AlumniColumns';
import { defaultColDefinition } from '../../utils/data/DefaultColumn';
import CustomModal from '../CustomModal/CustomModal';
import TicketModal from '../DataModal/TicketModal';

const AlumniTicketTable = ({ searchString, setSearchString }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const gridRef = useRef();
    const dispatch = useDispatch();
    const alumniDetails = useSelector(state => state.alumni);
    const {data: alumniData, error} = alumniDetails;
    const gridStyle = { height: '100%', width: '100%' };
    const [filteredAlumniData, setFilteredAlumniData] = useState([]);
    const [selectedTicketData, setSelectedTicketData] = useState({});
    const [columnDefs] = useState(alumniColumnDefs);

    useEffect(() => {
        if (alumniData.length === 0 && error === '') {
          dispatch({ type: sagaActions.FETCH_ALUMNI_DATA });
        }
      }, [dispatch, alumniData.length, error]);

    useEffect(() => {
        const newAlumniList = alumniData.filter((alumniDetail) => alumniDetail["registration_id"].toString().toLowerCase().includes(searchString.toLowerCase()) || alumniDetail["full_name"].toLowerCase().includes(searchString.toLowerCase()))
        setFilteredAlumniData(newAlumniList);
      }, [searchString, alumniData]);

    const handleRowSelect = (event) => {
        setSelectedTicketData(event.data);
        onOpen();
    }

    return (
        <>
            <div style={gridStyle} className='ag-theme-alpine'>
                <AgGridReact
                    onRowClicked={handleRowSelect}
                    ref={gridRef}
                    rowData={searchString ? filteredAlumniData : alumniData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDefinition}
                    suppressExcelExport={true}
                ></AgGridReact>
            </div>
            <CustomModal modalHeader={"Update Ticket Status"} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <TicketModal selectedTicketData={selectedTicketData} closeDialog={onClose} setSearchString={setSearchString} />
            </CustomModal>
        </>
    )
}

export default  AlumniTicketTable