import Navbar from '../../../Components/Nav';
import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
  } from '@chakra-ui/react';
import { useState} from 'react';
import { FiSearch } from 'react-icons/all';
import { CloseIcon } from '@chakra-ui/icons';
import ChangePageTitle from '../../../Components/ChangePageTitle';
import AlumniTicketTable from '../../../Components/AlumniTicketTable/AlumniTicketTable';

export default function TicketStatus() {
    const [searchString, setSearchString] = useState('');
    const handleChange = (event) => setSearchString(event.target.value);

    return (
        <>
            <ChangePageTitle pageTitle="Ticket Status - Alumni Connect 2024"></ChangePageTitle>
            <Navbar breadCrumb={['Actions', 'Tickets', 'Alumni']}>
                <div className="search-bar-section">
                    <div className="search-bar-field">
                        <InputGroup>
                            <Input
                            variant="filled"
                            bg="white"
                            _focus={{
                                bg: 'white',
                            }}
                            placeholder='Enter Search String'
                            value={searchString}
                            onChange={handleChange}
                            />
                            <InputRightElement>
                            <FiSearch />
                            </InputRightElement>
                        </InputGroup>

                        <IconButton
                            ml='8px'
                            colorScheme='red'
                            aria-label='Clear Icon'
                            icon={<CloseIcon />}
                            onClick={() => setSearchString('')}
                        />
                    </div>
                </div>
                <div className='table-with-search table-with-tab'>
                    <AlumniTicketTable searchString={searchString} setSearchString={setSearchString} />;
                </div>
            </Navbar>
        </>
    )
}