import React from "react";
import "./header.scss";
import logo from "../../../images/logo-black.png";
import profile from "../../../images/profile.png";
import {useNavigate} from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
      <div className="header">
        <img className="logo-image" src={logo} alt="logo"/>
        <img className="profile-image" src={profile} alt="profile" onClick={() => navigate("/login")}/>
      </div>
  );
};

export default Header;