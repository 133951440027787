import { createSlice } from '@reduxjs/toolkit';

const initialAlumniData = {
  data: [],
  error: '',
};

const alumniSlice = createSlice({
  name: 'alumni',
  initialState: initialAlumniData,
  reducers: {
    setAlumniData: (state, { payload: data }) => {
      state.data = data.studentData;
      state.error = data.error;
    },
    refreshAlumniAttendanceData: (state, {payload: data}) => {
      const statusObject = data.reduce((result, currentData) => {
        const {registration_id, attendance_status, comment} = currentData;
        result[registration_id] = {
          attendance_status: {
            att_status_id: attendance_status?.att_status_id,
            description: attendance_status?.description,
            comment
          }
        };

        return result;
      }, {});

      state.data = state.data.map((alumniData) => {
        const updatedData = statusObject[alumniData.registration_id] || {};
        return {
        ...alumniData,
        attendance_status: {
          ...alumniData.attendance_status,
          ...updatedData.attendance_status,
        }
        }
      });
    },
    refreshAlumniTicketData: (state, {payload: data}) => {
      const statusObject = data.reduce((result, currentData) => {
        const {registration_id, ticket_status} = currentData;
        result[registration_id] = {
          ticket_status: {
            ticket_status_id: ticket_status?.ticket_status_id,
            description: ticket_status?.description,
          }
        };

        return result;
      }, {});

      state.data = state.data.map((alumniData) => {
        const updatedData = statusObject[alumniData.registration_id] || {};
        return {
        ...alumniData,
        ticket_status: {
          ...alumniData.ticket_status,
          ...updatedData.ticket_status,
        }
        }
      });
    },
    resetAlumniData: () => {
      return initialAlumniData;
    },
  },
});

export const {
  setAlumniData,
  resetAlumniData,
  refreshAlumniAttendanceData,
  refreshAlumniTicketData,
} = alumniSlice.actions;

export default alumniSlice.reducer;
