export const SHARE_CONTENT = "Hey!\n" +
    "\n" +
    "I hope you're doing great! I wanted to let you know about Alumni Connect 2024, our upcoming alumni meet, and I'd love for you to join me there.\n" +
    "It's going to be an amazing time to catch up, reminisce about the good old days, and create some new memories together.\n" +
    "\n" +
    "📅 Date: 2-Oct-2024\n" +
    "📍 Venue: MGR Janaki College\n" +
    "\n" +
    "You can register here: alumnimeet.maatramtimes.com\n" +
    "\n" +
    "It would be so awesome to see you and hang out again.\n" +
    "Let’s make Alumni Connect 2024 an unforgettable event!\n" +
    "\n" +
    "Can't wait to see you there!";

export const AFTER_REGISTER_SHARE_CONTENT = "Hey!\n" +
    "\n" +
    "I have registered for Alumni Connect 2024, and I'd love for you to join me there.\n" +
    "It's going to be an amazing time to catch up, reminisce about the good old days, and create some new memories together.\n" +
    "\n" +
    "📅 Date: 2-Oct-2024\n" +
    "📍 Venue: MGR Janaki College\n" +
    "\n" +
    "You can register here: alumnimeet.maatramtimes.com\n" +
    "\n" +
    "It would be so awesome to see you and hang out again.\n" +
    "Let’s make Alumni Connect 2024 an unforgettable event!\n" +
    "\n" +
    "Can't wait to see you there!";

export const EVENT_CONTENT = {
  title: 'Maatram Alumni Meet 2024',
  description: 'Join us for the Maatram Alumni Meet 2024',
  startTime: new Date('2024-10-02T09:00:00'),
  endTime: new Date('2024-10-02T17:00:00'),
  location: 'MGR Janiki College, Chennai'
};

export const EVENT_DATE = "2nd Oct 2024 - 9:00AM to 5:00PM";
export const LOCATION_URL = "https://maps.app.goo.gl/yPYqZzksfC7SLXCJ6";
export const LOCATION_NAME = "Dr. MGR Janaki College of Arts and Science";
export const DEPLOYED_URL = "https://alumnimeet.maatramtimes.com/";