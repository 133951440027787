import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const CustomMenu = ({setSelectedOption, selectedOption, options}) => {
  const { description } = selectedOption;
  const filterKey = selectedOption.attendance_status_id !== undefined ? 'attendance_status_id' : 'ticket_status_id';
  const menuButtonRef = useRef(null);
  const [menuListWidth, setMenuListWidth] = useState(200);

  useEffect(() => {
    if(menuButtonRef.current !== null) {
      setMenuListWidth(menuButtonRef.current.getBoundingClientRect().width);
    }
  }, [menuButtonRef]);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => option[filterKey] !== selectedOption[filterKey]);
  }, [selectedOption, filterKey, options])

  return (
    <Menu>
      <MenuButton
        ref={menuButtonRef}
        my={1}
        w={"100%"}
        textAlign={"left"}
        fontWeight="normal"
        bg={"white"}
        border={"1px solid #E2E8F0"}
        _hover={{
          bg: "white",
          borderColor: "#cbd5e0",
        }}
        _active={{
          bg: "white",
          border: "1px solid #3182ce",
          boxShadow: "0 0 0 1px #3182ce",
        }}
        as={Button}
        rightIcon={<ChevronDownIcon boxSize={5} />}
      >
        {description}
      </MenuButton>
      <MenuList minWidth={`${menuListWidth+16}px`}>
        {
          filteredOptions.map((status) => <MenuItem key={status[filterKey]} onClick={() => setSelectedOption(status)}>{status?.description}</MenuItem>)
        }
      </MenuList>
    </Menu>
  );
};

export default CustomMenu;