import React, { createRef, useEffect, useState } from 'react';
import DataItem from './DataItem';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../store/sagaActions';
import { Button, Input } from '@chakra-ui/react';
import CustomMenu from '../CustomModal/CustomMenu';
import ChangePageTitle from '../ChangePageTitle';

const AlumniModal = ({selectedAlumniData, closeDialog, setSearchString}) => {
  const {
    registration_id,
    full_name,
    passed_out_year,
    contact_number,
    email,
    college,
    status: { description = 'Not Filled', attendance_status_id = 1 } = {},
    comment = '',
    accompany_count,
    ticket_status,
    stream,
    food_preference
    // gender: { gender_name },
    // days_attending,
  } = selectedAlumniData;
  const {data: attendanceOption, error: attendanceError} = useSelector(state => state.option.attendance)
  const dispatch = useDispatch();

  const commentSectionRef = createRef();
  const [selectedStatusCode, setSelectedStatusCode] = useState({description, attendance_status_id});

  useEffect(() => {
    if(attendanceOption.length === 0 && attendanceError === '') {
      dispatch({type: sagaActions.FETCH_OPTION_DATA});
    }
  }, []);

  const updateData = () => {
    const {attendance_status_id = 3} = selectedStatusCode;

    dispatch({
      type: sagaActions.UPDATE_ALUMNI_STATUS,
      payload: {
        "registration_id": registration_id,
        "attendance_status": {
          "att_status_id": attendance_status_id,
          "description": description
        },
        "comment": commentSectionRef.current.value,
        "callback": closeDialog,
        "setSearchString": setSearchString,
      }
    });
  }

  return (
    <div className='custom-modal student-modal'>
      <ChangePageTitle pageTitle='Update Attendance - Alumni Connect 2024'></ChangePageTitle>
      <div className='custom-modal__row'>
        <DataItem label={'Registration ID'} value={`A${String(registration_id).padStart(4, '0')}`} />
        <DataItem label={' Passed Out Year'} value={passed_out_year.year_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Name'} value={full_name} />
        {/* <DataItem label={'Gender'} value={gender_name} /> */}
        <DataItem label={"Accompany Count"} value={accompany_count} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Email'} value={email} />
        <DataItem label={'Food Preference'} value={food_preference.food_preference} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'College'} value={college.college_name} />
        <DataItem label={'Stream'} value={stream.stream_name} />
      </div>

      <div className='custom-modal__row'>
        <DataItem label={'Phone Number'} value={contact_number} />
        {/* <DataItem label={'Room No'} value={'Nil'} /> */}
        <DataItem label={'Ticket Status'} value={ticket_status.description}/>
      </div>

      {/* <div className='custom-modal__row'>
        <DataItem label={'Days Attending'} value={days_attending} />
      </div> */}

      <div className='custom-modal__row'>
        <div className="select-field">
          <div className="select-field__label">Attendance</div>
          <CustomMenu setSelectedOption={setSelectedStatusCode} selectedOption={selectedStatusCode} options={attendanceOption} />
        </div>
      </div>

      <div className='custom-modal__row'>
        <div className="input-field">
          <div className="select-field__label">Comment</div>
          <Input colorScheme={'blue'} bg={'#FFF'} ref={commentSectionRef} my={1} defaultValue={comment}></Input>
        </div>
      </div>

      <div className='custom-modal__row'>
        <Button onClick={updateData} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default AlumniModal;