export const alumniColumnDefs = [
    {
        field: "registration_id",
        headerName: "Registration ID",
        cellRendererParams: {
            color: '#e7b100'
        },
        cellRenderer: (data) => {
            return `A${String(data.value).padStart(4, '0')}`;
        },
    },
    {
        field: "full_name",
        cellStyle: { fontWeight: 'bold' },
        headerName: "Full Name"
    },
    {
        field: "passed_out_year.year_name",
        headerName: "Passed Out Year"
    },
    {
        field: "contact_number",
        headerName: "Phone Number"
    },
    {
        field: "stream.stream_name",
        headerName: "Stream"
    },
    {
        field: "attendance_status.description",
        headerName: "Attendance Status"
    },
    {
        field: "email",
        headerName: "Email"
    },
    {
        field: "college.college_name",
        headerName: "College"
    },
    {
        field: "accompany_count",
        headerName: "Acc Count"
    },
    {
        field: "ticket_status.description",
        headerName: "Ticket Status"
    }
]
