import React from "react";
import ChangePageTitle from "../../../Components/ChangePageTitle";
import Navbar from "../../../Components/Nav";
import SuccessScreen from "../../Register/SuccessScreen/successScreen";
import "./Ticket.scss";

const Ticket = () => {
  return (
      <>
        <ChangePageTitle pageTitle='Maatram Alumni Connect'></ChangePageTitle>
        <Navbar>
          <div className="ticket-page">
            <SuccessScreen />
          </div>
        </Navbar>
      </>
  );
}

export default Ticket;
