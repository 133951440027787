import React, {useEffect, useState} from "react";
import "./successScreen.scss";
import successIcon from "../../../images/successIcon.png";
import {FaMapSigns, FaRegCalendarAlt} from "react-icons/all";
import {CopyIcon} from "@chakra-ui/icons";
import vegIcon from "../../../images/veg.png";
import nonVegIcon from "../../../images/nonVeg.png";
import whatsAppIcon from '../../../images/whatsapp.png';
import inProgress from "../../../images/in-progress.png"
import {createIcsFile} from "../../../utils/icsFile";
import {
  AFTER_REGISTER_SHARE_CONTENT,
  DEPLOYED_URL,
  EVENT_CONTENT,
  LOCATION_URL,
  SHARE_CONTENT
} from "../../../utils/constants";
import {toast} from "react-toastify";
import axios from 'axios';
import { jwtHeader } from "../../../utils/AuthManager";
import { useSelector } from "react-redux";

const SuccessScreen = ({ credentials }) => {
  const [calenderUrl, setCalenderUrl] = useState('');
  const [showTicket, setShowTicket] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const profileData = useSelector(state => state.profile);
  const { userName: Name,  regId: reg_id } = profileData;
  const displayName = credentials && credentials.name ? credentials.name : Name;

  useEffect(() => {
    const icsFileContent = createIcsFile(EVENT_CONTENT);
    const blob = new Blob([icsFileContent], { type: 'text/calendar;charset=utf-8;' });
    setCalenderUrl(URL.createObjectURL(blob));
    if (!!reg_id) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/ticket',
        data: {
          "registration_id": reg_id
        },
        headers: jwtHeader()
      }).then((response) => {
        setShowTicket(response.data.show_ticket);
        setTicketDetails(response.data);
      }).catch((e) => {
        console.log(e);
        toast.error("Error in fetching form data, Please refresh the page.");
      });
    }
  }, [reg_id]);

  const ticketDataRenderer = (data) => {
    if(data === "Vegetarian") {
      return <img src={vegIcon} alt={"Veg Icon"} />;
    } else {
      return <img src={nonVegIcon} alt={"Non Veg Icon"} />;
    }
  }

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(AFTER_REGISTER_SHARE_CONTENT);
    toast.success("Copied to clipboard! Thanks for sharing!");
  }

  const copyPasswordtoClipboard = async () => {
    await navigator.clipboard.writeText(credentials.password);
    toast.success("Copied to clipboard!");
  }

  const handleShare = async () => {
    const shareData = {
      title: "Invite to Alumni Connect 2024",
      text: SHARE_CONTENT,
      url: DEPLOYED_URL,
    };

    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        await copyToClipboard();
      }
    } else {
      await copyToClipboard();
    }
  }

  return (
    <div className="success-content">
      <div className="ticket">
        {/*Success Icon*/}
        <img className="success-icon" src={successIcon} alt={"success icon"}/>

        <div className="welcome-section">
          {/*Welcome message*/}
          <div className="welcome-message">Welcome <span className="welcome-message__name">{displayName}</span> <br /> to Maatram Alumni Meet 2024</div>

          {/*Date Section*/}
          <div className="event-details-section" onClick={() => window.open(calenderUrl, "_blank")}>
            <FaRegCalendarAlt color={"#000"} size={24}/>
            <div className="details">
              <div>2nd Oct 2024 - 9:00AM to 5:00PM</div>
              <div className="details__link">Click to add to calendar</div>
            </div>
          </div>

          {/*Location Section*/}
          <div className="event-details-section" onClick={() => window.open(LOCATION_URL, "_blank")}>
            <FaMapSigns color={"#000"} size={32}/>
            <div className="details">
              <div>Dr. MGR Janaki College of Arts and Science for Women</div>
              <div className="details__link">Click to view location in map</div>
            </div>
          </div>

          {/*Login Details or Ticket Status Section*/}
          {credentials ? <div className="login-section">
            <div className="login-section__header">Login Details</div>
            <div className="login-section__email">Email : <span>{credentials.userName}</span></div>
            <div className="login-section__pass">
              <div className="login-section__pass__content">Pass : <span>{credentials.password}</span></div>
              <CopyIcon color={"#000"} size={22} onClick={copyPasswordtoClipboard}/>
            </div>
          </div> :
          <div className="status">
            {showTicket ?
            <div className="status__success">
              <div>Success</div>
              <img src={successIcon} alt={"Success"} />
              </div>
            :
            <div className="status__in-progress">
              <div>In Progress</div>
              <img src={inProgress} alt={"In Progress"}/>
            </div>}
          </div>}

          <div className="whatsapp-link">
              <img src={whatsAppIcon} alt='whatsapp' />
              <a href="https://chat.whatsapp.com/KbnXgVvI4uq0W7txNvrseO" target="_blank" rel="noreferrer"><span><b>Stay in the Loop!</b></span> <span>Join our <b>WhatsApp Group</b> for the Latest Updates!</span></a>
          </div>
        </div>

        <div>
          {/*Ticket Details*/}
          {showTicket ? <div className="ticket-details">
                <div className="ticket-details__column">
                  <div className="ticket-details__row">
                    <div className="ticket-details__row__title">Ticket ID</div>
                    <div className="ticket-details__row__data">{ticketDetails.ticket_id}</div>
                  </div>
                  <div className="ticket-details__row">
                    <div className="ticket-details__row__title">Batch</div>
                    <div className="ticket-details__row__data">{ticketDetails.passed_out_year.year_name}</div>
                  </div>
                  <div className="ticket-details__row">
                    <div className="ticket-details__row__title">Member ID</div>
                    <div
                        className="ticket-details__row__data">{ticketDetails.membership_id ? ticketDetails.membership_id : '-'}</div>
                  </div>
                </div>
                <div className="ticket-details__column">
                  <div className="ticket-details__row">
                    <div className="ticket-details__row__title">Valid For</div>
                    <div className="ticket-details__row__data">{ticketDetails.accompany_count + 1} Person(s)</div>
                  </div>
                  <div className="ticket-details__row">
                    <div className="ticket-details__row__title">Food&nbsp;Preference</div>
                    <div className="ticket-details__row__data">{ticketDataRenderer(ticketDetails.food_preference.food_preference)}</div>
                  </div>
                </div>
            </div> :
          <div className="inprogress-details">
            The ticket ID will be created soon. Kindly check back after a little while. <br /> <br />
            <span>Thank you for your patience!</span>
          </div>}
        </div>
      </div>

      {/*Share section*/}
      <div className="share-message">Ask your Maatram friends to join with you on the event</div>
      <div className="share-button" onClick={handleShare}>Share with Friends</div>
    </div>
  );
};

export default SuccessScreen;
