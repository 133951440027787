import "./App.css";
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import AllStudents from './pages/Desk/Student/AllStudents';
import AllAlumni from './pages/Desk/Alumni/AllAlumni';
import FilterGenders from './pages/Admin/Filter/Gender/Genders';
import ProtectedRoute from './utils/auth/ProtectedRoute';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import AuthMiddleware from './utils/auth/AuthMiddleware';
import Attendance from './pages/Desk/Attendance/Attendance';
import "./Styles/index.scss";
import FilterAttendance from "./pages/Admin/Filter/StudentPresentAbsent/Attendance";
import FilterYear from "./pages/Admin/Filter/Year/Year";
import FilterCollege from "./pages/Admin/Filter/College/College";
import Register from "./pages/Register/register";
import Ticket from "./pages/User/Ticket/Ticket";
import TicketStatus from "./pages/Admin/UpdateTicketStatus/TicketStatus";

function App() {
  return (
    <Routes>
      {/*User Registration*/}
      <Route path = "/" element = { <Register />}></Route>

      {/*Login*/}
      <Route path = "/login" element = { <AuthMiddleware><Login /></AuthMiddleware> }></Route>

      {/*User Pages*/}
      <Route path = "/ticket" element = { <ProtectedRoute allowedRoles={['User']}><Ticket /></ProtectedRoute> }></Route>

      {/*Dashboard*/}
      <Route exact path = "/dashboard" element = { <ProtectedRoute allowedRoles={['Admin']}><Dashboard /></ProtectedRoute> }></Route>

      {/*Members Page*/}
      <Route exact path = "/students" element = { <ProtectedRoute allowedRoles={['Admin']}><AllStudents /></ProtectedRoute> }></Route>
      <Route exact path = "/alumni" element = { <ProtectedRoute allowedRoles={['Admin']}><AllAlumni /></ProtectedRoute> }></Route>

      {/*Actions Page*/}
      <Route exact path = "/attendance" element = { <ProtectedRoute allowedRoles={['Desk', 'Admin']}><Attendance /></ProtectedRoute> }></Route>
      <Route exact path = "/update_ticket" element = { <ProtectedRoute allowedRoles={['Admin']}><TicketStatus /></ProtectedRoute>}></Route>

      {/*Analytics Page*/}
      <Route exact path = "/filter-by-gender" element = { <ProtectedRoute allowedRoles={['Admin']}><FilterGenders /></ProtectedRoute> }></Route>
      <Route exact path = "/filter-by-attendance/display-all" element = { <ProtectedRoute allowedRoles={['Admin']}><FilterAttendance/></ProtectedRoute> }></Route>
      <Route exact path = "/filter-by-year/display-all" element = { <ProtectedRoute allowedRoles={['Admin']}><FilterYear /></ProtectedRoute> }></Route>
      <Route exact path = "/filter-by-college/display-all" element = { <ProtectedRoute allowedRoles={['Admin']}><FilterCollege/></ProtectedRoute> }></Route>
      <Route exact path = "/analytics" element = { <ProtectedRoute allowedRoles={['Admin']}><FilterGenders /></ProtectedRoute> }></Route>
    </Routes>
  );
}

export default App;
