function createIcsFile(event) {
  const { title, description, startTime, endTime, location } = event;
  let icsFileContent = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `DTSTART:${formatDateToIcs(startTime)}`,
    `DTEND:${formatDateToIcs(endTime)}`,
    `LOCATION:${location}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\n');

  return icsFileContent;
}

function formatDateToIcs(date) {
  return date.toISOString().replace(/-|:|\.\d\d\d/g, '');
}

module.exports = {createIcsFile};