import React, {useRef, useState} from 'react';
import './register.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import InfoArea from "./InfoArea/infoArea";
import FormArea from "./FormArea/formArea";
import Header from "./Header/header";
import SuccessScreen from "./SuccessScreen/successScreen";

export default function Register() {
  const formRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [credentials, setCredentials] = useState({});

  if (successMessage) {
    return (
        <div id="success-page">
          <Header/>
          <SuccessScreen credentials={credentials} />
        </div>
    );
  }

  return (
      <div id="registration-page">
      <Header />
      <div className="content-area">
        <InfoArea formRef={formRef} />
        <FormArea formRef={formRef} successMessage={setSuccessMessage} credentials={setCredentials} />
      </div>
    </div>
  );
};
