import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { defaultColDefinition } from '../../utils/data/DefaultColumn';
import { sagaActions } from '../../store/sagaActions';
import { useDispatch, useSelector } from 'react-redux';
import { alumniColumnDefs } from './AlumniColumns';
import { useDisclosure } from '@chakra-ui/react';
import CustomModal from '../CustomModal/CustomModal';
import AlumniModal from '../DataModal/AlumniModal';

const AlumniTable = ({searchString, from, setSearchString}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const alumniDetails = useSelector(state => state.alumni);
  const {data: alumniData, error} = alumniDetails;
  const [filteredAlumniData, setFilteredAlumniData] = useState([]);
  const [selectedAlumniData, setSelectedAlumniData] = useState({});

  const [columnDefs] = useState(alumniColumnDefs);
  const gridStyle = { height: '100%', width: '100%' };

  useEffect(() => {
    if (alumniData.length === 0 && error === '') {
      dispatch({ type: sagaActions.FETCH_ALUMNI_DATA });
    }
  }, [dispatch, alumniData.length, error]);

  useEffect(() => {
    const newAlumniList = alumniData.filter((alumniDetail) => alumniDetail["registration_id"].toString().toLowerCase().includes(searchString.toLowerCase()) || alumniDetail["full_name"].toLowerCase().includes(searchString.toLowerCase()))
    setFilteredAlumniData(newAlumniList);
  }, [searchString, alumniData]);

  const handleRowSelect = (event) => {
    setSelectedAlumniData(event.data);
    onOpen();
  }

  return (
    <div style={gridStyle} className='ag-theme-alpine'>
      <AgGridReact
        onRowClicked={handleRowSelect}
        ref={gridRef}
        rowData={searchString ? filteredAlumniData : alumniData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDefinition}
        suppressExcelExport={true}
      ></AgGridReact>

      <CustomModal modalHeader={"Update Attendance"} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <AlumniModal selectedAlumniData={selectedAlumniData} closeDialog={onClose} setSearchString={setSearchString} />
      </CustomModal>
    </div>
  );
};

export default AlumniTable;