import { removeLoading, setLoading } from '../reducer/loaderSlice';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { jwtHeader } from '../../utils/AuthManager';
import { setOption } from '../reducer/optionSlice';
import { sagaActions } from '../sagaActions';

function* fetchOptionData() {
  try {
    yield put(setLoading());
    const response = yield call(() => axios.get(`${process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT}/content/registration`, {
      headers: jwtHeader()
    }).then((res) => res));

    if(response?.status === 200) {
      const { attendance_statuses, ticket_statuses } = response.data;

      yield put(setOption({
        optionName: 'attendance',
        optionValue: attendance_statuses,
        error: ''
      }));
      yield put(setOption({
        optionName: 'ticket',
        optionValue: ticket_statuses,
        error: ''
      }));
    } else {
      toast.error('Something went wrong, Please try again');
      yield put(setOption({
        optionName: 'attendance',
        optionValue: [],
        error: 'Something went wrong, Please try again',
      }));
      yield put(setOption({
        optionName: 'ticket',
        optionValue: [],
        error: 'Something went wrong, Please try again',
      }));
    }
  }  catch (error) {
    toast.error(error.message);
    yield put(setOption({
      optionName: 'attendance',
      optionValue: [],
      error: error.message,
    }));
    yield put(setOption({
      optionName: 'ticket',
      optionValue: [],
      error: error.message,
    }));  } finally {
    yield put(removeLoading());
  }
}

export default function* optionSaga() {
  yield takeLatest(sagaActions.FETCH_OPTION_DATA, fetchOptionData);
}
