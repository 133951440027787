import {Carousel} from "react-responsive-carousel";
import {CgCalendar, CgShare, GoLocation, MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/all";
import React, {useEffect, useState} from "react";
import {createIcsFile} from "../../../utils/icsFile";
import {toast} from "react-toastify";
import carousalImage3 from "../../../images/slider/slider-13.png";
import carousalImage1 from "../../../images/slider/slider-11.png";
import carousalImage2 from "../../../images/slider/slider-12.png";
import {EVENT_CONTENT, EVENT_DATE, LOCATION_NAME, LOCATION_URL, SHARE_CONTENT} from "../../../utils/constants";

const InfoArea = ({formRef}) => {
  const carousalItems = [carousalImage1, carousalImage2, carousalImage3];
  const [carousalPosition, setCarousalPosition] = useState(0);
  const [calenderUrl, setCalenderUrl] = useState('');

  const analyticsItems = [
    {
      metric: "900+",
      label: "Alumnus"
    },
    {
      metric: "1",
      label: "Day"
    },
    {
      metric: "6+",
      label: "Events & Games"
    }
  ];

  useEffect(() => {
    const icsFileContent = createIcsFile(EVENT_CONTENT);
    const blob = new Blob([icsFileContent], { type: 'text/calendar;charset=utf-8;' });
    setCalenderUrl(URL.createObjectURL(blob));
  }, []);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(SHARE_CONTENT);
    toast.success("Copied to clipboard! Thanks for sharing!");
  }

  const handleShare = async () => {
    const shareData = {
      title: "Invite to Alumni Connect 2024",
      text: SHARE_CONTENT,
      url: "demo.com",
    };

    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        await copyToClipboard();
      }
    } else {
      await copyToClipboard();
    }
  }

  const nextCarousalSlide = () => setCarousalPosition(carousalPosition + 1);
  const preCarousalSlide = () => setCarousalPosition(carousalPosition - 1);

  const executeScroll = () => formRef.current.scrollIntoView();

  return (
      <div className="info-area">
        <Carousel
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            autoPlay={true}
            swipeable={false}
            showArrows={false}
            infiniteLoop={true}
            selectedItem={carousalPosition}
            onChange={setCarousalPosition}
        >
          {carousalItems.map((item, index) => (
              <div className="slider-image" key={index} style={{backgroundImage: `url(${item})`}}/>
          ))}
        </Carousel>
        <MdKeyboardArrowLeft className="arrow-section-left" size={20} onClick={preCarousalSlide}/>
        <MdKeyboardArrowRight className="arrow-section-right" size={20} onClick={nextCarousalSlide}/>
        <div className="info-content">
          <div className="header-row">
            <h1>Maatram Alumni Meet 2024</h1>
            <CgShare onClick={handleShare} className="share-icon" color={"#FFF"} size={28}/>
          </div>
          <div className="info-row">
            <CgCalendar color={"#FFF"} size={20}/>
            <h3 onClick={() => window.open(calenderUrl, "_blank")}>{EVENT_DATE}</h3>
          </div>
          <div className="info-row">
            <GoLocation color={"#FFF"} size={20}/>
            <h3 onClick={() => window.open(LOCATION_URL, "_blank")}>{LOCATION_NAME}</h3>
          </div>

          <div className="analytics-section">
            {analyticsItems.map((item, index) => (
                <>
                  <div className="analytics-section__division">
                    <div className="metric">{item.metric}</div>
                    <div className="label">{item.label}</div>
                  </div>
                  {index < analyticsItems.length - 1 && <div className="hr"/>}
                </>
            ))}
          </div>

          <div className="welcome-content">
            Welcome back, esteemed alumni! Reconnect with old friends, reminisce about cherished memories, and
            celebrate our shared legacy at our alumni meet. Join us for an unforgettable reunion!
          </div>

          <div className="register-button" onClick={executeScroll}>Register</div>
        </div>
      </div>
  );
};

export default InfoArea;
