import Navbar from '../../../Components/Nav';
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css'; 
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; 
import ChangePageTitle from '../../../Components/ChangePageTitle';
import { FiSearch } from 'react-icons/all';
import StudentTable from '../../../Components/StudentTable/StudentTable';
import { CloseIcon } from '@chakra-ui/icons';

export default function AllRegister() {

  const [searchString, setSearchString] = useState('');
  const handleChange = (event) => setSearchString(event.target.value);

  return (
    <>
      <ChangePageTitle pageTitle='All Students - Alumni Connect 2024'></ChangePageTitle>
      <Navbar breadCrumb={['Members', 'All Students']}>

        <div className="search-bar-section">
          <div className="search-bar-field">
            <InputGroup>
              <Input
                variant='filled'
                bg='white'
                _focus={{
                  bg: 'white'
                }}
                placeholder='Enter Search String'
                value={searchString}
                onChange={handleChange}
              />
              <InputRightElement>
                <FiSearch />
              </InputRightElement>
            </InputGroup>

            <IconButton
              ml='8px'
              colorScheme='red'
              aria-label='Clear Icon'
              icon={<CloseIcon />}
              onClick={() => setSearchString('')}
            />
          </div>
        </div>

        <div className='full-table'>
          <StudentTable searchString={searchString} setSearchString={setSearchString} />
        </div>

      </Navbar>
    </>
  );
}
