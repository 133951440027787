import { useMemo, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../store/sagaActions';
import Navbar from '../../../../Components/Nav';
import ChangePageTitle from '../../../../Components/ChangePageTitle';
import { collegeColumnDefs } from './CollegeColumns';
import { defaultColDefinition } from '../../../../utils/data/DefaultColumn';
import { useNavigate } from 'react-router-dom';
import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/all';
import { CloseIcon } from '@chakra-ui/icons';

export default function FilterCollege() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentDetails = useSelector((state) => state.student);
  const { data: studentData, error } = studentDetails;
  const [searchString, setSearchString] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (studentData.length === 0 && error === '') {
      dispatch({ type: sagaActions.FETCH_STUDENT_DATA });
    }
  }, [dispatch, studentData.length, error]);

  const rowData = useMemo(() => {
    if (studentData.length === 0) return [];

    const genderObject = studentData.reduce((prevData, currentStudent) => {
      const { college: { college_id, college_name }, status: { status_name } } = currentStudent;

      if (!prevData.hasOwnProperty(college_id)) {
        prevData[college_id] = { collegeId: college_id, college: college_name, total: 0 };
      }

      const currentStatusKey = status_name.toString().replace(' ', '').toLowerCase();
      if (!prevData[college_id].hasOwnProperty(currentStatusKey)) {
        prevData[college_id][currentStatusKey] = 1;
      } else {
        prevData[college_id][currentStatusKey] += 1;
      }

      prevData[college_id]['total'] += 1;

      return prevData;
    }, {});

    return Object.values(genderObject);
  }, [studentData]);

  useEffect(() => {
    const newFilterList = rowData.filter((collegeData) => collegeData["college"].toString().toLowerCase().includes(searchString.toLowerCase()))
    setFilteredList(newFilterList);
  }, [searchString, rowData]);

  const handleClick = (event) => {
    navigate(`/students?college=${event.data.collegeId}`);
  };

  const handleChange = (event) => setSearchString(event.target.value);

  return (
    <>
      <ChangePageTitle pageTitle='Alumni Connect 2024 | Count - Colleges' />
      <Navbar breadCrumb={['Analytics', 'Student-College']}>

        <div className="search-bar-section">
          <div className="search-bar-field">
            <InputGroup>
              <Input
                variant='filled'
                bg='white'
                _focus={{
                  bg: 'white'
                }}
                placeholder='Enter Search String'
                value={searchString}
                onChange={handleChange}
              />
              <InputRightElement>
                <FiSearch />
              </InputRightElement>
            </InputGroup>

            <IconButton
              ml='8px'
              colorScheme='red'
              aria-label='Clear Icon'
              icon={<CloseIcon />}
              onClick={() => setSearchString('')}
            />
          </div>
        </div>

        <div className='ag-theme-alpine table-with-search'>
          <AgGridReact
            rowData={searchString ? filteredList : rowData}
            columnDefs={collegeColumnDefs}
            defaultColDef={defaultColDefinition}
            suppressExcelExport={true}
            onRowClicked={handleClick}
          />
        </div>
      </Navbar>
    </>
  );
}
