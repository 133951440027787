import { useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../../../store/sagaActions';
import Navbar from '../../../../Components/Nav';
import ChangePageTitle from '../../../../Components/ChangePageTitle';
import { genderColumnDefs } from './GenderColumns';
import { defaultColDefinition } from '../../../../utils/data/DefaultColumn';
import { useNavigate } from 'react-router-dom';

export default function FilterGenders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentDetails = useSelector((state) => state.student);
  const { data: studentData, error } = studentDetails;

  useEffect(() => {
    if (studentData.length === 0 && error === '') {
      dispatch({ type: sagaActions.FETCH_STUDENT_DATA });
    }
  }, [dispatch, studentData.length, error]);

  const rowData = useMemo(() => {
    if (studentData.length === 0) return [];

    const genderObject = studentData.reduce((prevData, currentStudent) => {
      const { gender: { gender_id, gender_name }, status: { status_name } } = currentStudent;

      if (!prevData.hasOwnProperty(gender_id)) {
        prevData[gender_id] = { genderId: gender_id, gender: gender_name, total: 0 };
      }

      const currentStatusKey = status_name.toString().replace(' ', '').toLowerCase();
      if (!prevData[gender_id].hasOwnProperty(currentStatusKey)) {
        prevData[gender_id][currentStatusKey] = 1;
      } else {
        prevData[gender_id][currentStatusKey] += 1;
      }

      prevData[gender_id]['total'] += 1;

      return prevData;
    }, {});

    return Object.values(genderObject);
  }, [studentData]);

  const handleClick = (event) => {
    navigate(`/students?gender=${event.data.genderId}`);
  };

  return (
    <>
      <ChangePageTitle pageTitle='Alumni Connect 2024 | Count - Genders' />
      <Navbar breadCrumb={['Analytics', 'Student-Gender']}>
        <div className='ag-theme-alpine table-without-search'>
          <AgGridReact
            rowData={rowData}
            columnDefs={genderColumnDefs}
            defaultColDef={defaultColDefinition}
            suppressExcelExport={true}
            onRowClicked={handleClick}
          />
        </div>
      </Navbar>
    </>
  );
}
