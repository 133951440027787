import React, { useEffect, useState } from 'react';
import DataItem from './DataItem';
import { useDispatch, useSelector } from 'react-redux';
import { sagaActions } from '../../store/sagaActions';
import { Button } from '@chakra-ui/react';
import CustomMenu from '../CustomModal/CustomMenu';
import ChangePageTitle from '../ChangePageTitle';
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";

const TicketModal = ({ selectedTicketData, closeDialog, setSearchString }) => {
    const {
        registration_id,
        full_name,
        passed_out_year,
        contact_number,
        email,
        college,
        status: { description = 'Not Filled', ticket_status_id = 2 } = {},
        total_amount,
        membership_type
      } = selectedTicketData;

      const [imageUrl, setImageUrl] = useState(null);
      const [editable, setEditable] = useState(false);
      const [paymentFile, setPaymentFile] = useState(null);
      const { data: ticketOption, error: ticketError } = useSelector(state => state.option.ticket);
      const dispatch = useDispatch();
      const [selectedStatusCode, setSelectedStatusCode] = useState({description, ticket_status_id});

      useEffect(() => {
        if(ticketOption.length === 0 && ticketError === '') {
          dispatch({type: sagaActions.FETCH_OPTION_DATA});
        }

        axios.post(
            process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/image',
            {
              "registration_id": registration_id
            },
            { responseType: 'blob' }
        ).then((response) => {
            const url = URL.createObjectURL(response.data);
            setImageUrl(url);
        }).catch((error) => {
            console.log(error);
        });
      }, []);

      const updateData = () => {
        const {ticket_status_id = 1} = selectedStatusCode;
    
        dispatch({
          type: sagaActions.UPDATE_ALUMNI_TICKET_STATUS,
          payload: {
            "registration_id": registration_id,
            "ticket_status": {
              "ticket_status_id": ticket_status_id,
              "description": description
            },
            "callback": closeDialog,
            "setSearchString": setSearchString,
          }
        });
      }

      const handleEdit = () => {
        setEditable(prevState => !prevState);
      }

      const handleCancel = () => {
        setEditable(prevState => !prevState);
      }

      const handleDelete = () => {
        setPaymentFile(null);
      }

      const handleSave = () => {
        axios.post(
            process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/file_upload',
            {
              "file": paymentFile,
              "registration_id": registration_id
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then((response) => {
          if(response.status === 200) {
            axios.post(
                process.env.REACT_APP_BACKEND_FASTAPI_ENDPOINT + '/registration/image',
                {
                  "registration_id": registration_id
                },
                { responseType: 'blob' }
            ).then((response) => {
                const url = URL.createObjectURL(response.data);
                setImageUrl(url);
            }).catch((error) => {
                console.log(error);
            });
            setEditable(false);
            setPaymentFile(null);
            toast.success("Payment File Updated.");
          }
        }).catch((e) => {
          console.log(e);
          toast.error("Error in uploading payment screenshot, Please try again.");
        })
      }

      const handleFileChange = (file) => {
        setPaymentFile(file)
      }

      const fileUploadSection = () => (
        paymentFile ?
        <>
            <div className="payment-file-preview-section">
                <img src={URL.createObjectURL(paymentFile)} alt='payment-file'/>
                <div className='buttons'>
                    <Button onClick={handleSave} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
                        Save
                    </Button>
                    <Button onClick={handleDelete} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
                        Delete
                    </Button>
                </div>
            </div>
        </>
        :
        <>
            <div className="payment-file-upload-text">Upload the payment screenshot</div>
            <div className="payment-file-upload-section">
                <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    classes="payment-file-uploader"
                    maxSize={3}
                    onTypeError={() => toast.error("Please upload a valid image file")}
                    onSizeError={() => toast.error("File size should be less than 3MB")}
                    types={["JPG", "PNG", "JPEG"]}
                />
            </div>
            <Button onClick={handleCancel} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
                Cancel
            </Button>
        </>
    );
  
    return (
        <div className='update-ticket-modal'>  
            <div className='custom-modal__alumni-ticket-modal'>
                <ChangePageTitle pageTitle='Update Attendance - Alumni Connect 2024'></ChangePageTitle>
                <div className='custom-modal__row'>
                    <DataItem label={'Registration ID'} value={`A${String(registration_id).padStart(4, '0')}`} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Passed Out Year'} value={passed_out_year.year_name} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Name'} value={full_name} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Email'} value={email} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'College'} value={college.college_name} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Phone Number'} value={contact_number} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Member Type'} value={membership_type.membership_type} />
                </div>

                <div className='custom-modal__row'>
                    <DataItem label={'Amount to be Pay'} value={total_amount} />
                </div>

                <div className='custom-modal__row'>
                    <div className="select-field">
                    <div className="select-field__label">Ticket Status</div>
                    <CustomMenu setSelectedOption={setSelectedStatusCode} selectedOption={selectedStatusCode} options={ticketOption} />
                    </div>
                </div>

                <div className='custom-modal__row'>
                    <Button onClick={updateData} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
                    Update
                    </Button>
                </div>
            </div>
            <div className='custom-modal__column'>
                <DataItem label={'Payment Screenshot'} />
                {editable ? fileUploadSection() : imageUrl && <img src={imageUrl} alt="Payment Screenshot" />}
                {!editable && <Button onClick={handleEdit} type={"submit"} bg='#fdc400' color='#000' w='100%' my={3} _hover={{ bg: '#fdc400' }}>
                Edit
                </Button>}
            </div>
        </div>
    )
}

export default TicketModal