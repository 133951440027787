import {ColorModeScript, ChakraProvider} from '@chakra-ui/react';
import React, {StrictMode} from 'react';
import * as ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import {ToastContainer} from 'react-toastify';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import 'react-toastify/dist/ReactToastify.min.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const queryClient = new QueryClient();

root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <ChakraProvider>
          <BrowserRouter>
            <StrictMode>
              <ColorModeScript>
              </ColorModeScript>
              <App/>
            </StrictMode>
          </BrowserRouter>
        </ChakraProvider>
      </QueryClientProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
